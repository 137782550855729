import React from 'react';

const MixedDropDown = ({ name, label, error, options, ...rest }) => {

    return (
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <select
                name={name}
                id={name}
                {...rest}
                className="form-control">
                <option value="" />
                {options.map(option => {
                    return (
                        Object.entries(option).map(([key, value]) => {
                            return (<option key={key} value={value}>{key}</option>)
                        })
                    )
                })}
            </select>
            {error && <div className="alert alert-danger">{error}</div>}
        </div >
    );
}

export default MixedDropDown;