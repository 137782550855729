import http from "./httpService";
import config from "../config/config.json";

const apiUrl = process.env.REACT_APP_API_URL || config.api_url;
const apiEndpoint = apiUrl + "/diagnoses";

export function getDiagnoses() {
  return http.get(apiEndpoint);
}

export function addDiagnosis(diagnosis) {
  return http.post(apiEndpoint, diagnosis);
}

export function getDiagnosis(id) {
  return http.get(apiEndpoint + `/${id}`);
}

export function saveDiagnosis(diagnosis) {
  if (diagnosis._id) {
    const body = { ...diagnosis };
    delete body._id;
    return http.put(apiEndpoint + `/${diagnosis._id}`, body);
  }
  return http.post(apiEndpoint, diagnosis);
}
