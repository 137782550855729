import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = ({ user }) => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <div className="container">
                <div className="navbar-collapse">
                    <div className="navbar-nav">
                        <NavLink to='/' className="navbar-brand">Hep Interpreter</NavLink>
                        <NavLink to='/test' className="nav-item nav-link">Run Test</NavLink>
                        <NavLink to='/diagnoses' className="nav-item nav-link">Diagnoses</NavLink>
                        {!user &&
                            <React.Fragment>
                                <NavLink to="/login" className="nav-item nav-link">Login</NavLink>
                            </React.Fragment>
                        }
                        {user &&
                            <React.Fragment>
                                <NavLink to="/profile" className="nav-item nav-link">Hello {user.name}!</NavLink>
                                <NavLink to="/logout" className="nav-item nav-link">Logout</NavLink>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;