import axios from "axios";
import { toast } from "react-toastify";
import config from "../config/config.json";
import jwtDecode from "jwt-decode";

const http = axios;
const apiUrl = process.env.REACT_APP_API_URL || config.api_url;
const apiEndpoint = apiUrl + "/auth";

function getRefreshToken() {
  return localStorage.getItem("refreshToken");
}

function getAccessToken() {
  return localStorage.getItem("accessToken");
}

function isTokenValid(token) {
  if (!token) return;
  const decoded = jwtDecode(token);
  return Date.now() < 1000 * decoded.exp;
}

function timeTillExpire(token) {

  if (isTokenValid(token)) return 1000 * jwtDecode(token).exp - Date.now();
  return 0;
}

async function refreshToken(refreshToken) {
  try {
    const { data } = await http.post(apiEndpoint + "/refresh", refreshToken);
    localStorage.setItem("accessToken", data.accessToken);
    console.log("token refreshed", data.accessToken);
  } catch (error) {
    console.log(error);
  }
}

async function tokenGetter(token) {
  if (isTokenValid(token)) {
    if (timeTillExpire(token) > 250000) return getAccessToken();
    return await refreshToken(getRefreshToken());
  }
  try {
    await refreshToken(getRefreshToken());
    return getAccessToken();
  } catch (error) {
    toast("Your session has expired");
    window.location = "/login";
  }
}

export default {
  getRefreshToken,
  getAccessToken,
  isTokenValid,
  refreshToken,
  tokenGetter,
  timeTillExpire,
};
