import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { Route, Switch } from "react-router-dom";
import Navbar from "./components/navbar";
import NotFound from "./components/notFound";
import Diagnoses from "./components/diagnoses";
import SeralogicTest from "./components/serologicTest";
import LoginForm from "./components/loginForm";
import auth from "./services/authService";
import Logout from "./components/logout";
import DiagnosisForm from "./components/diagnosisForm";
import SeralogicTestDetails from "./components/serologicTestDetails";
import HomePage from "./components/home";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

class App extends Component {
  state = {};

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    const { user } = this.state;

    return (
      <React.Fragment>
        <ToastContainer />
        <Navbar user={user} />
        <main className="container">
          <Switch>
            <Route path="/login" component={LoginForm} />
            <Route path="/logout" component={Logout} />
            <Route path="/diagnoses" exact component={Diagnoses} />
            <Route path="/diagnosis/:id" component={DiagnosisForm} />
            <Route path="/test" exact component={SeralogicTest} />
            <Route path="/test/:id" component={SeralogicTestDetails} />
            <Route path="/" exact component={HomePage} />
            <Route path="/not-found" component={NotFound} />
          </Switch>
        </main>
      </React.Fragment>
    );
  }
}

export default App;
