import React from 'react';

const DropDown = ({ name, label, error, options, valueProperty, ...rest }) => {

    return (
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <select
                name={name}
                id={name}
                {...rest}
                className="form-control">
                <option value="" />
                {options.map(option => (
                    <option
                        key={option._id}
                        value={option._id}>
                        {option[valueProperty]}
                    </option>
                ))}
            </select>
            {error && <div className="alert alert-danger">{error}</div>}
        </div >
    );
}

export default DropDown;