import React from 'react';

const BoolDropDown = ({ name, label, error, ...rest }) => {

    return (
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <select
                name={name}
                id={name}
                {...rest}
                className="form-control">
                <option value="" />
                <option value="true">+</option>
                <option value="false">-</option>
            </select>
            {error && <div className="alert alert-danger">{error}</div>}
        </div >
    );
}

export default BoolDropDown;