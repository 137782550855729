import jwtDecode from "jwt-decode";
import http from "./httpService";
import config from "../config/config.json";

const apiUrl = process.env.REACT_APP_API_URL || config.api_url;
const apiEndpoint = apiUrl + "/auth/token";
const refreshToken = "refreshToken";
const accessToken = "accessToken";
http.setJwt(getJwt());

export async function login(email, password) {
  const { data } = await http.post(apiEndpoint, { email, password });
  localStorage.setItem(accessToken, data.accessToken);
  localStorage.setItem(refreshToken, data.refreshToken);
}

export function loginWithJwt(jwt) {
  localStorage.setItem(accessToken, jwt);
}

export function getJwt() {
  return localStorage.getItem(accessToken);
}

export function logout() {
  localStorage.removeItem(accessToken);
  localStorage.removeItem(refreshToken);
}

function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(accessToken);
    return jwtDecode(jwt);
  } catch (error) {
    return null;
  }
}

export default {
  login,
  logout,
  getCurrentUser,
  loginWithJwt,
  getJwt,
};
