import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
    return (<div>
        <div className="jumbotron">
            <h1 className="display-4">Welcome to the Hepatitis Test Interpreter!</h1>
            <p>***Please note: all tests are for educational purposes only***</p>
            <hr className="my-4" />
            <p>Here are the available tests:</p>
            <Link
                to="/test"
                className="btn btn-primary"
            >Hep B Serologic</Link>
        </div>
    </div>);
}

export default HomePage;