import React, { Component } from 'react';
import Joi from 'joi-browser';
import Input from './input';
import RequiredInput from './requiredInput';
import DropDown from './dropDown';
import BoolDropDown from './boolDropDown';
import MixedDropDown from './mixedDropDown';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import MixedDropdown from './requiredMixedDropDown';
import TextArea from './textArea';

class Form extends Component {
    state = {
        data: {},
        errors: {}
    };

    validate = () => {

        const options = { abortEarly: false };
        const { error } = Joi.validate(this.state.data, this.schema, options);

        if (!error) return null;

        const errors = {};
        for (let item of error.details)
            errors[item.path[0]] = item.message;
        return errors;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) return;
        this.doSubmit();
    };

    validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] };
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null;
    }

    handleChange = ({ currentTarget: input }) => {

        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);

        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = { ...this.state.data };
        data[input.name] = input.value;
        this.setState({ data, errors });
    };

    renderButton = (label) => {
        return <button disabled={this.validate()} className="btn btn-primary">{label}</button>
    }

    renderInput = (name, label, type = 'text') => {
        const { data, errors } = this.state;
        return (
            <Input
                type={type}
                name={name}
                value={data[name]}
                label={label}
                onChange={this.handleChange}
                error={errors[name]}
            />
        );
    }

    renderTextArea = (name, label, required, type = 'text') => {
        const { data, errors } = this.state;
        return (
            <TextArea
                type={type}
                name={name}
                value={data[name]}
                label={label}
                onChange={this.handleChange}
                error={errors[name]}
                required={required}
            />
        );
    }

    renderRequiredInput = (name, label, type = 'text') => {
        const { data, errors } = this.state;
        return (
            <RequiredInput
                type={type}
                name={name}
                value={data[name]}
                label={label}
                onChange={this.handleChange}
                error={errors[name]}
            />
        );
    }

    renderDropDown = (name, label, options, valueProperty) => {
        const { data, errors } = this.state;

        return (
            <DropDown
                name={name}
                value={data[name]}
                label={label}
                options={options}
                valueProperty={valueProperty}
                error={errors[name]}
                onChange={this.handleChange}
            />
        );
    }

    renderBoolDropDown = (name, label) => {
        const { errors } = this.state;

        return (
            <BoolDropDown
                name={name}
                label={label}
                error={errors[name]}
                onChange={this.handleChange}
            />
        );
    }

    renderMixedDropDown = (name, label, options, required) => {
        const { data, errors } = this.state;

        return (
            <MixedDropDown
                name={name}
                value={data[name]}
                label={label}
                options={options}
                error={errors[name]}
                required={required}
                onChange={this.handleChange}
            />
        );
    }

    renderMixedDropdown = (name, label, options, required) => {
        const { data, errors } = this.state;

        return (
            <MixedDropdown
                name={name}
                value={data[name]}
                label={label}
                options={options}
                error={errors[name]}
                required={required}
                onChange={this.handleChange}
            />
        );
    }

    renderRadioButton = (name, label, options) => {
        const { data, errors } = this.state;

        return (
            <RadioButton
                name={name}
                value={data[name]}
                label={label}
                options={options}
                error={errors[name]}
                onChange={this.handleChange}
            />
        );
    }
}


export default Form;