import React from 'react';
import Form from './common/form';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import { saveDiagnosis, getDiagnosis } from '../services/diagnosisService';

class DiagnosisForm extends Form {
    state = {
        data: { name: '', diagnosis: '' },
        errors: {}
    };

    schema = {
        _id: Joi.string(),
        name: Joi.string().min(3).max(255).required(),
        diagnosis: Joi.string().min(5).max(2048).required()
    };

    async componentDidMount() {
        await this.populateDiagnosis();
    }

    async populateDiagnosis() {
        try {
            const diagnosisId = this.props.match.params.id;
            if (diagnosisId === "new") return;
            const { data: diagnosis } = await getDiagnosis(diagnosisId);
            this.setState({ data: this.mapModelToView(diagnosis) });
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 404)
                this.props.history.replace('/not-found');
        }
    }

    mapModelToView(diagnosis) {
        return {
            _id: diagnosis._id,
            name: diagnosis.name,
            diagnosis: diagnosis.diagnosis
        }
    }

    doSubmit = async () => {
        try {
            await saveDiagnosis(this.state.data);
            this.props.history.push('/diagnoses');
        } catch (error) {
            toast(error);
        }
    };

    render() {
        return (
            <div>
                <h1>Diagnosis</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.renderRequiredInput('name', 'Diagnosis Name')}
                    {this.renderTextArea('diagnosis', 'Diagnosis Explanation', true)}
                    {this.renderButton('Submit')}
                </form>
            </div>
        );
    }
}

export default DiagnosisForm;