import React, { Component } from 'react';

class TextArea extends Component {

    renderError = () => {

        const { error, required, value } = this.props;

        if (required) {
            if (error || value === '')
                return "is-invalid";
            return "is-valid";
        }
        else {
            if (error)
                return "is-invalid";
            return "is-valid";
        }
    }

    render() {
        const { name, label, error, required, ...rest } = this.props;
        return (
            <div className="form-group" >
                <label htmlFor={name}>{label}</label>
                <textarea
                    {...rest}
                    name={name}
                    id={name}
                    className={"form-control " + this.renderError()}
                ></textarea>
                { error && <div className="alert alert-danger">{error}</div>}
            </div >);
    }
}

export default TextArea;