import React, { Component } from 'react';
import { getTestSubmission } from '../services/serTestService';
import TestDataTable from './testDataTable';


class SerologicTestDetails extends Component {


    state = {
        testData: [],
        diagnosis: {
            name: '',
            explanation: ''
        },
    }

    async componentDidMount() {
        await this.populateDiagnosis();
    }

    async populateDiagnosis() {
        try {
            const { id } = this.props.match.params;
            const { data } = await getTestSubmission(id);
            const { testData, diagnosis } = data;
            this.setState({ testData: Object.entries(testData), diagnosis });
        } catch (error) {
            this.props.history.replace('/not-found');
        }
    }

    render() {

        const testData = this.state.testData;
        const diagnosis = this.state.diagnosis;

        return (
            <div>
                <h1>Results</h1>
                <p>
                    The results of the submitted test is <strong>{diagnosis.name}</strong>
                </p>
                <h5>Explanation:</h5>
                <p>{diagnosis.explanation}</p>
                <h5>Tests submitted:</h5>
                <TestDataTable
                    testData={testData}
                />
            </div>
        );
    }
}

export default SerologicTestDetails;