import React, { Component } from 'react';
import DiagnosesTable from './diagnosesTable';
import { getDiagnoses } from '../services/diagnosisService';
import { Link } from 'react-router-dom';
import auth from '../services/authService';
import _ from 'lodash';

class Diagnoses extends Component {
    state = {
        diagnoses: [],
        sortColumn: { path: 'name', order: 'asc' }
    };

    async componentDidMount() {
        const { data: diagnoses } = await getDiagnoses();
        this.setState({ diagnoses });
    };

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    };

    sortDiagnoses = sortColumn => {
        const { diagnoses } = this.state;
        return _.orderBy(diagnoses, sortColumn.path, sortColumn.order);
    };

    render() {

        const { sortColumn } = this.state;
        const diagnoses = this.sortDiagnoses(sortColumn);
        const user = auth.getCurrentUser();

        return (
            <div>
                <h2>Diagnoses</h2>
                {
                    user && <Link to="/diagnosis/new" className="btn btn-primary" style={{ marginBottom: 20, marginTop: 20 }}>Add New</Link>
                }
                <DiagnosesTable
                    diagnoses={diagnoses}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                />
            </div >
        );
    }
}

export default Diagnoses;