import React, { Component } from 'react';

class RequiredInput extends Component {

    renderError = () => {
        if (this.props.error || (this.props.value === ''))
            return "is-invalid";
        return "is-valid";
    };

    render() {

        const { name, label, error, ...rest } = this.props;

        return (
            <div className="form-group" >
                <label htmlFor={name}>{label}</label>
                <input
                    {...rest}
                    name={name}
                    id={name}
                    className={"form-control " + this.renderError()}
                />
                { error && <div className="alert alert-danger">{error}</div>}
            </div >
        );
    }
}

export default RequiredInput;
