import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Table from './common/table';

class DiagnosesTable extends Component {

    columns = [
        {
            path: 'name',
            label: 'Name',
            content: diagnosis => (<Link to={`/diagnosis/${diagnosis._id}`}>{diagnosis.name}</Link>)
        }
    ]

    render() {

        const { diagnoses, onSort, sortColumn } = this.props;

        return (
            <Table
                columns={this.columns}
                data={diagnoses}
                sortColumn={sortColumn}
                onSort={onSort}
            />
        );
    }
}

export default DiagnosesTable;