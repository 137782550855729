import React from 'react';

function renderIcon(arr) {

    if (arr[1] !== true && arr[1] !== false)
        return arr[1];
    return (
        !arr[1] ? <i className="fa fa-minus" style={{ color: "#dc3545" }} /> : <i className="fa fa-plus" style={{ color: "#007bff" }} />
    );
}

const TestDataTable = ({ testData: data }) => {
    return (
        <table className="table">
            <thead>
                <tr>
                    <th>
                        Test
                        </th>
                    <th>
                        Result
                        </th>
                </tr>
            </thead>
            <tbody>
                {
                    data.map((key) => {
                        return (
                            <tr>
                                <td key={data.indexOf(key) + key.indexOf(key[0])}>
                                    {key[0]}
                                </td>
                                <td key={data.indexOf(key) + key.indexOf(key[1])}>
                                    {renderIcon(key)}
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    );
}

export default TestDataTable;