import React, { Component } from 'react';

class MixedDropdown extends Component {

    renderError = () => {
        const { error, value, required } = this.props;

        if (required) {
            if (error || !value)
                return "is-invalid";
            return "is-valid";
        }
        else {
            if (error)
                return "is-invalid";
            return "is-valid";
        }
    };

    render() {

        const { name, label, error, options, ...rest } = this.props;

        return (
            <div className="form-group">
                <label htmlFor={name}>{label}</label>
                <select
                    name={name}
                    id={name}
                    {...rest}
                    className={"form-control " + this.renderError()} >
                    <option value="" />
                    {options.map(option => {
                        return (
                            Object.entries(option).map(([key, value]) => {
                                return (<option key={key} value={value}>{key}</option>)
                            })
                        )
                    })}
                </select>
                {error && <div className="alert alert-danger">{error}</div>}
            </div >
        );
    }
}

export default MixedDropdown;