import http from "./httpService";
import config from "../config/config.json";

const apiUrl = process.env.REACT_APP_API_URL || config.api_url;
const apiEndpoint = apiUrl + "/test";

export function runTest(testData) {
  console.log(apiEndpoint);
  return http.post(apiEndpoint, testData);
}

export function getTestSubmission(id) {
  return http.get(apiEndpoint + `/${id}`);
}
