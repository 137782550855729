import React from 'react';
import Joi from 'joi-browser';
import Form from './common/form';
import { toast } from "react-toastify";
import { runTest } from '../services/serTestService';

class SeralogicTest extends Form {

    state = {
        data: {},
        errors: {},
        response: {}
    }

    schema = {
        HBsAg: Joi.boolean().required(),
        antiHBc: Joi.boolean().required(),
        antiHBs: Joi.boolean().required(),
        IgmAntiHBc: Joi.boolean(),
        HBeAg: Joi.boolean(),
        HBVDNA: Joi.number().min(0).max(10000000),
        ALT: Joi.string()
    };

    boolOptions = [
        { '+': "true" },
        { '-': "false" }
    ]

    alt = [
        { "elevated (> xxxx)": "high" },
        { "normal   (< yyyy)": "normal" }
    ]

    doSubmit = async () => {
        try {
            const response = await runTest(this.state.data);
            this.setState({ response });
            this.props.history.push(`/test/${response.data._id}`)
        }
        catch (error) {
            toast('No diagnosis found. Check test data and try again', {
                position: toast.POSITION.TOP_CENTER
            });
            console.log(error);
        }
    }

    render() {
        return (
            <div>
                <h1>Hepatitis B Serologic Test</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.renderMixedDropdown('HBsAg', 'HBsAg', this.boolOptions, true)}
                    {this.renderMixedDropdown('antiHBc', 'antiHBc', this.boolOptions, true)}
                    {this.renderMixedDropdown('antiHBs', 'antiHBs', this.boolOptions, true)}
                    {this.renderMixedDropdown('IgmAntiHBc', 'IgmAntiHBc', this.boolOptions)}
                    {this.renderMixedDropdown('HBeAg', 'HBeAg', this.boolOptions)}
                    {this.renderMixedDropdown('ALT', 'ALT', this.alt)}
                    {this.renderButton('Submit')}
                </form>
            </div>
        );
    }
}

export default SeralogicTest;